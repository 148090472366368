import { getAssetsImg } from "@/common/utils/util"


// Home
const img0 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/animation/loading_factory_new.png')
const img1 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/part2_bg-2025-01-28.jpeg')
const img2 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/part2_mask.svg')
const img3 = getAssetsImg('home/cloud_mobile_bg.png')
const img4 = getAssetsImg('home/home_logo.png')
const img5 = getAssetsImg('home/prompt_icon.png')
const img6 = getAssetsImg('home/dialog/dialog_logo_none.png')
const img7 = getAssetsImg('home/dialog/logo_2_1.png')
const img8 = getAssetsImg('home/dialog/logo_2_2.png')
const img9 = getAssetsImg('home/dialog/logo_4_1.png')
const img10 = getAssetsImg('home/dialog/logo_5_1.png')
const img11 = getAssetsImg('home/dialog/logo_5_2.png')
const img12 = getAssetsImg('home/dialog/title_con_bg.png')
const img13 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/cloud_mobile.png')
const img14 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/cloud_bg.png')
const img15 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/cloud_1.png')
const img16 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/cloud_2.png')
const img17 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/cloud_3.png')
const img18 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/cloud_4.png')
const img19 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/cloud_5.png')
const img20 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/cloud_6.png')
const img21 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/cloud_7.png')
const img22 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/login/metamask_icon.svg')
const img23 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/login/coinbase_icon.svg')
const img24 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/login/wallet_collet_icon.svg')
const img25 = getAssetsImg('home/home_icon_1.png')
const img26 = getAssetsImg('home/home_icon_2.png')
const img27 = getAssetsImg('home/home_icon_3.png')
const img28 = getAssetsImg('home/home_icon_4.png')
const img29 = getAssetsImg('home/home_icon_5.png')
const img30 = getAssetsImg('home/home_little_icon_1.png')
const img31 = getAssetsImg('home/home_little_icon_2.png')
const img32 = getAssetsImg('home/home_little_icon_3.png')
const img33 = getAssetsImg('home/home_little_icon_4.png')
const img34 = getAssetsImg('home/home_little_icon_5.png')
const img35 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/loading_book_new.gif')
const img36 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/community-bg.png')
const img37 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/title-bg.png')
const img38 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/blue-circle.png')
const img39 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/community-bg-mobile.png')
const img40 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/community-img.png')
const img41 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/community-img-1.png')
const img42 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/community-img-2.png')
const img43 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/community-img-3.png')
const img44 = getAssetsImg('https://d8bzbt7gigqyu.cloudfront.net/web-pc/image/firefly/community-img-4.png')


export default [
    img0,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44
]
